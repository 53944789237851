import React, { useState } from "react"
import Papa from "papaparse"
import { PageLayout } from "@components/layout/PageLayout"
import { PageHeader } from "@components/header/PageHeader"
import { EyeOrganisation } from "@features/accountSwitcher/hooks/useUserOrganisations"
import { UploadCSVStep } from "@features/awareness/admin/recipientsUpload/UploadCSVStep"
import { OrganisationStep } from "@features/awareness/admin/recipientsUpload/OrganisationStep"
import {
    RecipientColumnSelection,
    RecipientRegistration,
    RecipientsUploadStep,
    UploadedCSV,
} from "@features/awareness/awarenessTypes"
import { ColumnSelectionStep } from "@features/awareness/admin/recipientsUpload/ColumnSelectionStep"
import { DataVerificationStep } from "@features/awareness/admin/recipientsUpload/DataVerificationStep"
import { usePostRecipients } from "@features/awareness/hooks/usePostRecipients"
import { Alert } from "@designSystem/feedback/notifications/Alert"
import { useAwarenessLanguages } from "@features/awareness/hooks/useAwarenessLanguages"
import { Typography } from "@eyectrl/baseplate-design-system"

// Default column selection
const columnOptions: RecipientColumnSelection[] = [
    {
        columnName: "email",
        index: 0,
    },
    {
        columnName: "firstName",
        index: 1,
    },
    {
        columnName: "lastName",
        index: 2,
    },
    {
        columnName: "language",
        index: 3,
    },
    {
        columnName: "department",
        index: 4,
    },
    {
        columnName: "jobTitle",
        index: 5,
    },
]

export const AwarenessCSVAdmin: React.FC = () => {
    // Organisation the CSV is going to be uploaded for
    const [confirmedOrganistion, setConfirmedOrganistion] = useState<EyeOrganisation>()
    // Represents the parsed csv file, after being uploaded during the UploadCSV Step.
    const [uploadedCSV, setUploadedCSV] = useState<UploadedCSV>()
    // Represents the data from the csv, mapped to the recipients data structure.
    const [recipientsData, setRecipientsData] = useState<RecipientRegistration[]>()

    const { data: awarenessLanguages, isPending: isPendingLanguages } = useAwarenessLanguages()

    const { mutate, isPending } = usePostRecipients()

    // Uses Papa parse to parse the uploaded csv, then maps it to a filename and body attribute which consists of an array with multiple rows.
    const onCSVUpload = (file: File) => {
        Papa.parse(file, {
            complete: function (results, file) {
                if (results && Array.isArray(results.data) && Array.isArray(results.data[0])) {
                    const csvData = results.data as string[][]
                    const csvWithoutEmptyRows = csvData.filter((row) => row.length > 1)

                    setUploadedCSV({ fileName: file.name, data: csvWithoutEmptyRows })
                }
            },
        })
    }

    const clearRecipientsStep = (step: RecipientsUploadStep) => {
        if (step === "SelectOrganisationStep") {
            setConfirmedOrganistion(undefined)
            setUploadedCSV(undefined)
            setRecipientsData(undefined)
        }

        if (step === "UploadStep") {
            setUploadedCSV(undefined)
            setRecipientsData(undefined)
        }

        if (step === "ColumnSelectionStep") {
            setRecipientsData(undefined)
        }
    }

    const onUploadRecipientsData = (recipientsToUpload: RecipientRegistration[]) => {
        if (confirmedOrganistion) {
            mutate(
                { eyed: confirmedOrganistion.eyed, recipients: recipientsToUpload },
                {
                    onSuccess: () => {
                        resetRecipientsUpload()
                    },
                },
            )
        }
    }

    // Resets the entire form to a new recipient list can be uploaded
    const resetRecipientsUpload = () => {
        setConfirmedOrganistion(undefined)
        setUploadedCSV(undefined)
        setRecipientsData(undefined)
    }

    if (isPendingLanguages) {
        return (
            <PageLayout variant="detailPage">
                <PageHeader title={"Awareness Administration"} />
                <Alert title="Fetching awareness languages..." type="loading" />
            </PageLayout>
        )
    }

    if (isPending) {
        return (
            <PageLayout variant="detailPage">
                <PageHeader title={"Awareness Administration"} />
                <Alert title="Uploading recipients..." type="loading" />
            </PageLayout>
        )
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={"Awareness Administration"} />

            <div className="bg-orange-100 py-3 px-5  rounded-md mb-4 space-y-2 border border-brand-eye-orange">
                <Typography variant="body-1-semibold">Uploading a CSV overrides current recipients</Typography>
                <Typography variant="body-2">
                    Uploading a new CSV for an organisation will override the current recipients for that organisation.
                    Make sure you want these changes to be applied before uploading.
                </Typography>

                <Typography variant="body-2">
                    * It could take up to an hour for changes to be synced to Phised.
                </Typography>
            </div>

            <div className="space-y-12">
                <OrganisationStep
                    confirmedOrganistion={confirmedOrganistion}
                    clearRecipientsStep={clearRecipientsStep}
                    confirmOrganistion={(organisation) => setConfirmedOrganistion(organisation)}
                />

                <UploadCSVStep
                    confirmedOrganistion={confirmedOrganistion}
                    clearRecipientsStep={clearRecipientsStep}
                    uploadedCSV={uploadedCSV}
                    onUploadCSV={onCSVUpload}
                />

                <ColumnSelectionStep
                    uploadedCSV={uploadedCSV}
                    recipientsData={recipientsData}
                    setRecipientsData={setRecipientsData}
                    columnOptions={columnOptions}
                    clearRecipientsStep={clearRecipientsStep}
                />

                <DataVerificationStep
                    columnOptions={columnOptions}
                    recipientsData={recipientsData}
                    setRecipientsData={setRecipientsData}
                    onUploadRecipientsData={onUploadRecipientsData}
                    awarenessLanguages={awarenessLanguages?.data ?? []}
                />
            </div>
        </PageLayout>
    )
}
